import { routes } from "../../constants/routes";
import {
  InstagramIcon,
  RightArrow,
  TwitterIcon,
  YoutubeIcon,
} from "../../design/icons";

const Footer = () => {
  return (
    <footer className="bg-dark w-full relative">
      <div className="w-full max-w-[1500px] mx-auto py-10 px-4">
        <div className="py-10 flex justify-between flex-col lg:flex-row gap-10">
          <div className="w-full lg:w-[23rem]">
            <a href={routes.home} className="w-[20rem]">
              <img
                src="/assets/logo-real.svg"
                alt=""
                width="100%"
                height="100%"
              />
            </a>
            <p className="text-sm text-textGray my-8">
              We are a professional cleaning company dedicated to providing
              top-quality cleaning services. With a skilled team, eco-friendly
              practices, and a commitment to customer satisfaction, we ensure
              spaces are spotless and hygienic.
            </p>
            <div className="flex items-center gap-8 text-white">
              {/* <TwitterIcon /> */}
              <a href="https://www.instagram.com/housekeepinghq/" target="_blank" rel="noreferrer">
                <InstagramIcon />
              </a>
              <a href="https://www.youtube.com/@HouseKeepingHQ" target="_blank" rel="noreferrer">
                <YoutubeIcon />
              </a>
              <a href="https://www.linkedin.com/company/housekeepinghq/" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                  <g clip-path="url(#clip0_1272_137)">
                    <path d="M18.002 0.863647H2.00195C0.901953 0.863647 0.00195312 1.76365 0.00195312 2.86365V18.8636C0.00195312 19.9636 0.901953 20.8636 2.00195 20.8636H18.002C19.102 20.8636 20.002 19.9636 20.002 18.8636V2.86365C20.002 1.76365 19.102 0.863647 18.002 0.863647ZM6.00195 17.8636H3.00195V8.86365H6.00195V17.8636ZM4.50195 7.16365C3.50195 7.16365 2.70195 6.36365 2.70195 5.36365C2.70195 4.36365 3.50195 3.56365 4.50195 3.56365C5.50195 3.56365 6.30195 4.36365 6.30195 5.36365C6.30195 6.36365 5.50195 7.16365 4.50195 7.16365ZM17.002 17.8636H14.002V12.5636C14.002 11.7637 13.302 11.0636 12.502 11.0636C11.702 11.0636 11.002 11.7637 11.002 12.5636V17.8636H8.00195V8.86365H11.002V10.0636C11.502 9.26365 12.602 8.66365 13.502 8.66365C15.402 8.66365 17.002 10.2636 17.002 12.1636V17.8636Z" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1272_137">
                      <rect width="20" height="20" fill="white" transform="translate(0 0.863647)" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a href="https://www.facebook.com/HouseKeepingHeadquarters" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                  <g clip-path="url(#clip0_1272_126)">
                    <path d="M17.5 0.863647H2.5C1.12125 0.863647 0 1.9849 0 3.36365V18.3636C0 19.7424 1.12125 20.8636 2.5 20.8636H10V13.9886H7.5V10.8636H10V8.36365C10 6.2924 11.6788 4.61365 13.75 4.61365H16.25V7.73865H15C14.31 7.73865 13.75 7.67365 13.75 8.36365V10.8636H16.875L15.625 13.9886H13.75V20.8636H17.5C18.8787 20.8636 20 19.7424 20 18.3636V3.36365C20 1.9849 18.8787 0.863647 17.5 0.863647Z" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1272_126">
                      <rect width="20" height="20" fill="white" transform="translate(0 0.863647)" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="flex flex-col gap-6">
              <h3 className="text-white font-bold">Home</h3>
              <div className="flex flex-col gap-4">
                <Link>About Us</Link>
                <Link href={routes.services}>Services</Link>
                <Link href={routes.joinCrew}>Join Our Crew</Link>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <h3 className="text-white font-bold">Services</h3>
              <div className="flex flex-col gap-4">
                <Link href={routes.services}>Post-Construction</Link>
                <Link href={routes.services}>Move-Out / Move-In</Link>
                <Link href={routes.services}>Real Estate Cleaning</Link>
                <Link href={routes.services}>
                  Residential - Deep Clean recurring Service
                </Link>
                <Link href={routes.services}>Office / Janitorial</Link>
              </div>
            </div>
          </div>
          {/* <div className="w-full lg:w-[25rem] h-max relative z-0">
            <input
              placeholder="Signup for Discounts"
              type="text"
              className="outline-none bg-[#F9F9FE] px-8 py-8 relative z-0 rounded-full w-full"
            />
            <button className="text-white bg-primary rounded-full absolute z-[1] w-12 h-12 flex items-center justify-center right-4 top-[calc(50%-1.5rem)]">
              <RightArrow size={1.25} />
            </button>
          </div> */}
        </div>
        <hr className="text-textGray" />
        <div className="flex flex-col-reverse lg:flex-row items-center justify-between gap-4 py-4">
          <h5 className="text-textGray text-sm">
            Copyright © 2024 HouseKeeping Headquarters
          </h5>
          <div className="flex items-center gap-2">
            <Link href={routes.terms}>
              <span className="text-sm">Terms of Use</span>
            </Link>
            <div className="w-[1px] h-[14px] bg-textGray" />
            <Link href={routes.policy}>
              <span className="text-sm">Privacy Policy</span>
            </Link>
          </div>
        </div>
      </div>

      <a
        href="https://wa.link/pb1jw4"
        target="_blank"
        rel="noreferrer"
        className="fixed  bottom-14 z-30 right-5 transition-all hover:scale-125"
      >
        <img src="/assets/whatsapp.png" alt="whats-app icon" />
      </a>
    </footer>
  );
};

export default Footer;

const Link = ({ children, href }) => {
  return (
    <a
      href={href}
      className="text-textGray hover:text-white transition-all duration-300  cursor-pointer"
    >
      {children}
    </a>
  );
};
