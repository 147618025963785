import React from "react";

const AboutUs = () => {
  return (
    <main className=" mt-[140px] p-6 md:p-12">
      <section className="max-w-4xl mx-auto text-center">
        <h1 className="text-3xl md:text-[50px] font-bold mb-6">About Us</h1>
        <p className="text-xl md:text-2xl font-medium mb-10">
          Premier Cleaning Services in Seattle
        </p>
        <p className="text-black text-start font-semibold  text-sm md:text-2xl mb-10">
          HouseKeeping HQ, cleaning services Seattle, post-construction
          cleaning, move-out cleaning Seattle, move-in cleaning Seattle, real
          estate cleaning Seattle, residential deep cleaning, recurring cleaning
          service, office cleaning Seattle, janitorial cleaning service,
          professional cleaning Seattle, top cleaning company Seattle, Seattle
          cleaning provider, reliable cleaning services Seattle, Seattle home
          cleaning, Seattle office cleaning.
          <br />
        </p>
        <p className="text-black text-start font-medium  text-sm md:text-2xl mb-10">
          HouseKeeping HQ offers top-notch cleaning services in Seattle,
          including post-construction, move-out/move-in, real estate,
          residential deep clean, and office cleaning.
        </p>
      </section>

      <section className="max-w-4xl mx-auto">
        <h2 className="text-2xl md:text-3xl font-bold mb-10">
          About HouseKeeping HQ
        </h2>
        <p className="text-black text-sm md:text-2xl font-semibold mb-10">
          HouseKeeping HQ is a cleaning service provider company based in
          Seattle; we offer high standard cleaning services for your Home,
          residences, and commercial establishments. We started with a vision to
          support clean spaces. Our focus remains on delivering quality services
          suitable to individual clients within premises. Whether the customer
          requires cleanliness of a house or an office space or any other space
          to be non-negotiable, our team of professionals will ensure that this
          is guaranteed.
        </p>

        <h2 className="text-2xl md:text-3xl font-bold mb-10">Our Mission</h2>
        <p className="text-black text-sm md:text-2xl font-semibold mb-10">
          At HouseKeeping HQ, our mission is simple: That is why our cleaning
          services are reputable, professional but affordable to ensure that the
          environment of our clientele is transformed for the better. Our goal
          is to partner with you in providing all your cleaning solutions from
          cleaning the newly constructed building to offering our special
          residential cleaning services at the highest level of quality and
          satisfaction.
        </p>

        <h2 className="text-2xl md:text-3xl font-bold mb-10">Our Vision</h2>
        <p className="text-black text-sm md:text-2xl font-semibold mb-10">
          Our vision is to be the premier cleaning service company in Seattle so
          that people look forward to green clean spaces. Our lofty goal is to
          help bring a positive change to our community through delivering
          services that introduce cleanliness, wellness, and cohesiveness to
          homes, businesses, and commercial, and real estate entities and
          establishments.
        </p>
      </section>

      <section className="max-w-4xl mx-auto">
        <h2 className="text-2xl md:text-3xl font-bold mb-8">What We Do</h2>
        <p className="mb-6 text-black font-semibold md:text-2xl text-base">
          We offer various cleaning services at HouseKeeping HQ to ensure that
          every client gets a service that can suit them. Our comprehensive
          services include:
        </p>
        <ul className="list-disc text-left pl-6 text-black text-sm md:text-base space-y-12">
          <li className="md:text-2xl text-base font-medium">
            <strong className="font-bold">Post-Construction Cleaning: </strong>
            Once a construction project is completed, we join in to help breathe
            life into the constructed environment. Our post-construction
            cleaning service means that each end of the construction area is
            neat, tidy, and fit for use with no debris left behind.
          </li>
          <li className="md:text-2xl text-base font-medium">
            <strong className="font-bold">Move-Out / Move-In Cleaning: </strong>
            Relocation is always a big task and we are here to ensure it becomes
            as easy as possible. With our move-out/move-in cleaning service we
            guarantee that every time a tenant is moving out or moving in, the
            property is clean.
          </li>
          <li className="md:text-2xl text-base font-medium">
            <strong className="font-bold">Real Estate Cleaning: </strong> We
            provide real estate cleaning services that help clean homes or
            commercial spaces to the best like making real estate ready and
            improve its marketability.
          </li>
          <li className="md:text-2xl text-base font-medium">
            <strong className="font-bold">
              Residential Deep Clean & Recurring Service:
            </strong>
            You may require moving cleaning services once in a while, which is
            why we offer you comprehensive cleaning services to capture
            whichever corner you need. Offering our cleaning schedules, you do
            not have to worry about the quality of your home because we ensure
            it is clean always.
          </li>
          <li className="md:text-2xl text-base font-medium">
            <strong className="font-bold">
              Office / Janitorial Cleaning Service:
            </strong>{" "}
            A clean, neat and clutter-free environment allows for optimal
            working and also gives the client a good first impression about your
            company. We offer daily and rigorous once-over office and janitorial
            services with respect to corporate business cleaning needs.
          </li>
        </ul>
      </section>

      <section className="max-w-4xl mx-auto mt-24">
        <h2 className="text-2xl md:text-3xl font-bold mb-10">Why Choose Us?</h2>
        <p className="text-black font-medium text-sm md:text-2xl mb-10">
          HouseKeeping HQ has been operating for many years in the Seattle area
          therefore our clients can rely on clean work, impeccable quality, and
          true service. This team is selected, has passed through the necessary
          background check, and makes sure that the results are optimized. You
          do not have to sign up for a recurring service.
        </p>
        <p className="text-black font-medium text-sm md:text-2xl mb-10">
          Our services can be provided in one service or multiple services
          depending on your request.
        </p>
        <p className="text-black font-medium text-sm md:text-2xl mb-10">
          HouseKeeping HQ is all about cleaning, an important part of which is
          the satisfaction of our consumers. We will leave the cleaning job with
          us as you attend to other important aspects all-round the year.
        </p>
        <p className="text-black font-medium text-sm md:text-2xl mb-10">
          You can contact us now to book for our services and see what
          professional cleaning done by competent personnel are all about.
        </p>
        <p className="text-black font-medium text-sm md:text-2xl mb-16">
          Don’t hesitate to contact us for your appointment for the service and
          enjoy working with a professional team that performs a cleaning
          service to its best.
        </p>
      </section>
    </main>
  );
};

export default AboutUs;
