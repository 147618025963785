import React from "react";

const TermsAndConditions = () => {
  return (
    <main className="bg-white py-8  px-4 md:px-20 lg:px-32 mx-auto mt-[150px] w-full">
      <div className="mx-auto w-[80%] text-center">
        <h2 className="text-2xl font-bold mb-4">Terms & Conditions</h2>
        <h1
          className="text-4xl font-bold
         mb-2"
        >
          HI-Terms of Use
        </h1>
        <p className="text-sm">Last updated: September 18, 2024</p>
        <p>
          Please read these terms and conditions carefully before using Our
          Service.
        </p>
      </div>

      <div className="mt-5  w-[70%] mx-auto flex flex-col gap-5">
        <section className="mb-8 mt-16">
          <h3 className="text-xl font-bold mb-8">
            Interpretation and Definitions
          </h3>
          <div className="px-6">
            <h4 className="text-lg font-bold mb-2">Interpretation</h4>

            <p className="mb-4 w-[60%]">
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or plural.
            </p>

            <h4 className="text-lg font-bold mb-2">Definitions</h4>
            <p className="mb-5">
              For the purposes of these Terms and Conditions:
            </p>

            <ul className="list-disc  ml-10 flex flex-col gap-4">
              <li className="mb-2">
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by, or is under common control with a party...
              </li>
              <li className="mb-2">
                <strong>Country</strong> refers to Nicaragua or United States.
              </li>
              <li className="mb-2">
                <strong>Company</strong> (referred to as "the Company", "We",
                "Us" or "Our" in this Agreement) refers to Housekeeping
                Headquarters.
              </li>
              <li className="mb-2">
                <strong>Service</strong> refers to the Website.
              </li>
              <li className="mb-2">
                <strong>Terms and Conditions</strong> (also referred to as
                "Terms") mean these Terms and Conditions that form the entire
                agreement between You and the Company regarding the use of the
                Service.
              </li>
              <li className="mb-2">
                <strong>Website</strong> refers to Housekeeping Headquarters,
                accessible from https://housekeepinghq.com/
              </li>
              <li className="mb-2">
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </li>
            </ul>
          </div>
        </section>

        <section className="mb-8">
          <h3 className="text-xl font-bold mb-6">Acknowledgment</h3>
          <p className="text-start">
            These are the Terms and Conditions governing the use of this Service
            and the agreement that operates between You and the Company. These
            Terms and Conditions set out the rights and obligations of all users
            regarding the use of the Service. <br></br> <br></br>Your access to
            and use of the Service is conditioned on Your acceptance of and
            compliance with these Terms and Conditions. These Terms and
            Conditions apply to all visitors, users and others who access or use
            the Service. <br></br> <br></br> By accessing or using the Service
            You agree to be bound by these Terms and Conditions. If You disagree
            with any part of these Terms and Conditions then You may not access
            the Service. <br></br> <br></br> You represent that you are over the
            age of 18. The Company does not permit those under 18 to use the
            Service. <br></br> <br></br> Your access to and use of the Service
            is also conditioned on Your acceptance of and compliance with the
            Privacy Policy of the Company. Our Privacy Policy describes Our
            policies and procedures on the collection, use and disclosure of
            Your personal information when You use the Application or the
            Website and tells You about Your privacy rights and how the law
            protects You. Please read Our Privacy Policy carefully before using
            Our Service.
          </p>
        </section>

        <section className="mb-8">
          <h3
            className="text-xl font-bold
           mb-2"
          >
            Links to Other Websites
          </h3>
          <p className="mb-4">
            Our Service may contain links to third-party websites or services
            that are not owned or controlled by the Company...
          </p>
          <p className="mb-4">
            We strongly advise You to read the terms and conditions and privacy
            policies of any third-party websites or services that You visit.
          </p>
        </section>

        <section className="mb-8">
          <h3
            className="text-xl font-bold
           mb-2"
          >
            Termination
          </h3>
          <p className="mb-4">
            We may terminate or suspend Your access immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if You breach these Terms and Conditions.
          </p>
          <p className="mb-4">
            Upon termination, Your right to use the Service will cease
            immediately.
          </p>
        </section>

        <section className="mb-8">
          <h3
            className="text-xl font-bold
           mb-2"
          >
            Limitation of Liability
          </h3>
          <p className="mb-4">
            Notwithstanding any damages that You might incur, the entire
            liability of the Company and any of its suppliers under any
            provision of this Terms and Your exclusive remedy for all of the
            foregoing shall be limited to the amount actually paid by You
            through the Service or 100 USD if You haven't purchased anything
            through the Service.
          </p>
          <p className="mb-4">
            To the maximum extent permitted by applicable law, in no event shall
            the Company or its suppliers be liable for any special, incidental,
            indirect, or consequential damages whatsoever.
          </p>
        </section>

        <section className="mb-8">
          <h3
            className="text-xl font-bold
           mb-2"
          >
            "AS IS" and "AS AVAILABLE" Disclaimer
          </h3>
          <p className="mb-4">
            The Service is provided to You "AS IS" and "AS AVAILABLE" and with
            all faults and defects without warranty of any kind. To the maximum
            extent permitted under applicable law, the Company makes no
            representations, warranties, or guarantees.
          </p>
        </section>

        <section className="mb-8">
          <h3
            className="text-xl font-bold
           mb-2"
          >
            Governing Law
          </h3>
          <p className="mb-4">
            The laws of the Country, excluding its conflicts of law rules, shall
            govern this Terms and Your use of the Service.
          </p>
        </section>

        <section className="mb-8">
          <h3
            className="text-xl font-bold
           mb-2"
          >
            Disputes Resolution
          </h3>
          <p className="mb-4">
            If You have any concern or dispute about the Service, You agree to
            first try to resolve the dispute informally by contacting the
            Company.
          </p>
        </section>

        <section className="mb-8">
          <h3
            className="text-xl font-bold
           mb-2"
          >
            United States Legal Compliance
          </h3>
          <p className="mb-4">
            You represent and warrant that (i) You are not located in a country
            that is subject to the United States government embargo...
          </p>
        </section>

        <section className="mb-8">
          <h3
            className="text-xl font-bold
         mb-2"
          >
            Severability and Waiver
          </h3>

          <div className="px-6 mt-7">
            <h4
              className="text-lg font-bold
         mb-2"
            >
              Severability
            </h4>
            <p className="mb-4">
              If any provision of these Terms is held to be unenforceable or
              invalid, such provision will be changed and interpreted to
              accomplish the objectives of such provision to the greatest extent
              possible under applicable law...
            </p>

            <h4
              className="text-lg font-bold
         mb-2"
            >
              Waiver
            </h4>
            <p className="mb-4">
              Except as provided herein, the failure to exercise a right or to
              require performance of an obligation under these Terms shall not
              affect a party's ability to exercise such right or require such
              performance at any time thereafter...
            </p>
          </div>
        </section>

        <div className="px-6">
          <section className="mb-8">
            <h3
              className="text-xl font-bold
         mb-2"
            >
              Translation Interpretation
            </h3>
            <p className="mb-4">
              These Terms and Conditions may have been translated if We have
              made them available to You on our Service. You agree that the
              original English text shall prevail in the case of a dispute
            </p>
          </section>

          <section className="mb-8">
            <h3
              className="text-xl font-bold
         mb-2"
            >
              Changes to These Terms and Conditions
            </h3>
            <p className="mb-4">
              We reserve the right, at Our sole discretion, to modify or replace
              these Terms at any time. If a revision is material, we will make
              reasonable efforts to provide at least 30 days' notice prior to
              any new terms taking effect. What constitutes a material change
              will be determined at Our sole discretion.
            </p>
            <p className="mb-4">
              By continuing to access or use Our Service after those revisions
              become effective, You agree to be bound by the revised terms. If
              You do not agree to the new terms, in whole or in part, please
              stop using the website and the Service.
            </p>
          </section>

          <section className="mb-8">
            <h3
              className="text-xl font-bold
         mb-2"
            >
              Contact Us
            </h3>
            <p className="mb-4">
              If you have any questions about these Terms and Conditions, You
              can contact us:
            </p>
            <ul className="list-disc list-inside">
              <li>
                By visiting this page on our website:{" "}
                <a href="https://housekeepinghq.com/" className="text-blue-500">
                  https://housekeepinghq.com/
                </a>
              </li>
              <li>By phone number: 201-884-5833</li>
            </ul>
          </section>
        </div>
      </div>
    </main>
  );
};

export default TermsAndConditions;
