import ContactForm from "../components/Contact/ContactForm";
import { Hero } from "../components/residential";
import { PhoneIcon } from "../design/icons";

const ContactPage = () => {
  return (
    <main className="mt-[100px] ">
      <section className="w-4/5 mx-auto mt-[16%] text-center">
        <h1 className="text-primary lg:text-7xl mb-9 font-bold ">
          We’d Love to Hear From You{" "}
        </h1>
        <p className="font-medium text-black">
          HouseKeeping Headquarters is eager to hear from you! Whether you need{" "}
          <br></br>
          to schedule, ask questions, or share feedback, reach out!
        </p>

        <div className="mt-16">
          <h2 className="text-black lg:text-5xl font-bold mb-5">
            Our professionals are standing by:
          </h2>

          <p className="font-medium lg:text-xl">
            We’re just a click away! Fill out the form to share your cleaning
            needs with us.
          </p>
        </div>

        <div className="px-[10%] mt-[5%]">
          <ContactForm />
        </div>

        <p className=" text-primary font-bold lg:text-4xl my-10">
          {" "}
          Give Us a Call
        </p>

        <a
          href="tel:+253-988-5813"
          class=" inline-flex items-center justify-start   font-bold rounded-full group"
        >
          <span class="  mb-20 mx-auto border-black border w-44 rounded-md px-4 py-2 text-left text-black transition-colors duration-200 ease-in-out group-hover:text-primary flex items-center gap-2">
            <PhoneIcon color="black" />
            <span className="font-bold">253-988-5813</span>
          </span>
        </a>
      </section>
    </main>
  );
};

export default ContactPage;
