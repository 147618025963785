import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";

const MyForm = () => {
  const [showFormSpinner, setShowFormSpinner] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      tellMeMore: "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    emailjs.init({
      publicKey: process.env.REACT_APP_EMAIL_PUBLIC_KEY,
    });
  }, []);

  const onSubmit = (data) => {
    setShowFormSpinner(true);
    clearErrors();
    try {
      emailjs
        .send(
          process.env.REACT_APP_EMAIL_SERVICE_ID,
          process.env.REACT_APP_EMAIL_TEMPLATE_ID,
          {
            from_name: data.firstName + " " + data.lastName,
            phone: data.phone,
            reply_to: data.email,
            message: data.tellMeMore,
          },
        )
        .then(
          () => {
            setSubmissionSuccess(true);
            setTimeout(() => {
              setSubmissionSuccess(false);
              reset();
            }, 1200);

          },
          (error) => {
            console.log(error);
            setError("api", {
              type: "manual",
              message:
                "An error occurred while sending the message. Please try again.",
            });
          },
        )
        .finally(() => setShowFormSpinner(false));
      } catch (error) {
        console.error(error)
      }
    }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5 relative " >
      {showFormSpinner && (
        <div class="absolute flex h-full w-full items-center justify-center rounded-2xl bg-white bg-opacity-50">
          <div class="relative">
            <div class="h-40 w-40 rounded-full border-b-8 border-t-8 border-gray"></div>
            <div class="absolute left-0 top-0 h-40 w-40 animate-spin rounded-full border-b-8 border-t-8 border-primary"></div>
          </div>
        </div>
      )}
      {submissionSuccess && (
        <div class="absolute flex h-full w-full items-center justify-center rounded-2xl bg-white bg-opacity-50">
          <div class="relative">
            <div>
              <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
            </div>
          </div>
        </div>
      )}
      <div className="flex md:flex-row flex-col mb-2 items-center">
        <div className="flex flex-grow flex-col gap-5">
          <input
            {...register("firstName", { required: "First name is required" })}
            placeholder="First Name"
            className="md:mr-2 border-2 border-[#F0F0F0] rounded-[10px] p-4 "
          />
          {errors.firstName && (
            <span className="text-red-500">{errors.firstName.message}</span>
          )}
        </div>

        <div className="flex flex-col flex-grow">
          <input
            {...register("lastName", { required: "Last name is required" })}
            placeholder="Last Name"
            className="border-2 border-[#F0F0F0] rounded-[10px]  p-4 "
          />
          {errors.lastName && (
            <span className="text-red-500">{errors.lastName.message}</span>
          )}
        </div>
      </div>

      <input
        {...register("email", {
          required: "Email is required",
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "Invalid email address",
          },
        })}
        placeholder="Email"
        className="mt-2 border-2 border-[#F0F0F0] rounded-[10px]  p-4 w-full"
      />
      {errors.email && (
        <span className="text-red-500">{errors.email.message}</span>
      )}

      <input
        {...register("phone", {
          required: "Phone number is required",
          minLength: {
            value: 10,
            message: "Phone number must be at least 10 digits",
          },
        })}
        placeholder="Phone"
        className="mt-2 border-2 border-[#F0F0F0] rounded-[10px]  p-4 w-full"
      />
      {errors.phone && (
        <span className="text-red-500">{errors.phone.message}</span>
      )}

      <textarea
        {...register("tellMeMore", { required: "Please tell us more" })}
        placeholder="Tell me more about your project goals"
        rows={10}
        className="mt-2 border-2 border-[#F0F0F0] rounded-[10px] p-4 w-full"
      />
      {errors.tellMeMore && (
        <span className="text-red-500">{errors.tellMeMore.message}</span>
      )}

      <div className="flex justify-center mt-4">
        <button
          type="submit"
          className="py-2 px-4 w-48 my-10 bg-[#387EE6] text-white rounded"
        >
          Send
        </button>
      </div>
    </form>
  );
};

export default MyForm;
