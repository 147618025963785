const Hero = () => {
  return (
    <section className="w-screen max-w-[1500px] mx-auto lg:h-[65vh] relative px-4 py-10 z-0 gap-10 flex justify-between flex-col lg:flex-row items-center">
      <div className="w-full lg:w-[50%] space-y-4">
        <h1 className="text-4xl text-center lg:text-left lg:text-8xl font-bold text-primary">
          Housekeeping
          <br />
          Headquarters
        </h1>
        <h4 className="text-xl text-center lg:text-left lg:text-3xl font-bold text-black">
          Your One Stop Shop
        </h4>
        <h5 className="text-lg text-center lg:text-left lg:text-2xl font-medium text-black">
          For your home cleaning needs
        </h5>
      </div>
      <div className="w-full lg:w-[calc(45%-2.5rem)]">
        <img
          src="/assets/residential/banner.png"
          alt=""
          width="100%"
          height="100%"
        />
      </div>
    </section>
  );
};

export default Hero;
