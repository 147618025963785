export const routes = {
  home: "/",
  services: "/services",
  faqs: "/faqs",
  joinCrew: "/join-crew",
  locations: "/locations",
  residential: "/residential",
  about: "/about",
  contact: "/contact",
  terms: "/terms",
  policy: "/policy",
};
