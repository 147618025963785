import { Button } from "../../design/components";
import { PhoneIcon } from "../../design/icons";

const Serving = () => {
	return (
		<section className="w-screen max-w-[1500px] mx-auto px-4 py-10">
			<h2 className="text-black text-4xl lg:text-5xl font-bold text-center">
				Proudly Serving the Seattle-Eastside Area
			</h2>
			<h4 className="text-center text-lg lg:text-2xl font-medium lg:max-w-[60%] mx-auto my-8">
				Housekeeping HQ provides superior house cleaning services
			</h4>
			<div className="w-full relative rounded-3xl overflow-hidden">
				<img
					src="/assets/residential/serving.png"
					alt=""
					width="100%"
					height="100%"
					className=""
				/>
				<div className="absolute w-full h-full left-0 top-0 flex items-center flex-col lg:flex-center justify-center gap-8">
					<a href="mailto:Headquarters@housekeepinghq.com">
						<Button theme="white">Get a quote</Button>
					</a>
					<a href="tel:+253-988-5813">
						<Button variant="outline" theme="white">
							<span className="flex items-center gap-2">
								<span>
									<PhoneIcon />
								</span>
								<span>253-988-5813</span>
							</span>
						</Button>
					</a>
				</div>
			</div>
			<ol className="w-full lg:w-[75%] mx-auto my-10 space-y-4">
				<li className="w-full bg-[#F0F0F0] p-8 text-xl font-semibold">
					1. Request an instant quote online.
				</li>
				<li className="w-full bg-[#F0F0F0] p-8 text-xl font-semibold">
					2. Book your service in less than a minute!
				</li>
				<li className="w-full bg-[#F0F0F0] p-8 text-xl font-semibold">
					3. We use a cleaning checklist, fully customizable!
				</li>
				<li className="w-full bg-[#F0F0F0] p-8 text-xl font-semibold">
					4. You get a home feeling brand new!
				</li>
				<li className="w-full bg-[#F0F0F0] p-8 text-xl font-semibold">
					5. We send a team of professional, experienced & friendly cleaners to
					your home
				</li>
			</ol>
		</section>
	);
};

export default Serving;
