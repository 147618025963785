import { Button } from "../../../design/components";
import { LocationIcon, PhoneIcon } from "../../../design/icons";
import Locations from "../../sections/Locations";

const Services = () => {
	return (
		<section className="w-full pb-4 lg:pb-10 relative">
			<div className="bg-primary py-8 px-4">
				<h2 className="text-center text-white font-bold text-5xl lg:text-7xl">
					Our Services
				</h2>
				<h4 className="text-center text-white text-lg lg:text-2xl font-medium lg:max-w-[60%] mx-auto my-4">
					HOUSEKEEPING HEADQUARTERS provides dependable & high-quality cleaning
					services that are customized for you
				</h4>
			</div>
			<div className="w-full max-w-[1500px] mx-auto px-4">
				<div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-8 py-10">
					<Card
						title="One-Time Deep Cleaning"
						image="/assets/home/services-1.svg"
						description="Our Deep Cleaning service is the best option if your home has not been professionally cleaned in a while or it is your first time with us."
					/>
					<Card
						title="Recurring Cleaning"
						image="/assets/home/services-2.svg"
						description="We offer Recurring Cleaning for those who need basic cleaning on a weekly, biweekly or monthly basis. This includes dusting, wiping, vacuuming and mopping the essential areas of the home. We can also change the bed sheets for you if you like."
					/>
					<Card
						title="Move-In / Move-Out"
						image="/assets/home/services-3.svg"
						description="Whether you’re entering a new home or exiting an old one, our Move-In / Move-Out Cleaning is for you. It includes the services from Deep Cleaning plus fridge, oven, windows, & cabinets cleaning. We will ensure that the whole interior of your home is prepared for the next occupant."
					/>
					<Card
						title="Post Construction"
						image="/assets/home/services-4.svg"
						description="We will perform a comprehensive cleaning of your remodeled project or newly built home. We will clean and wipe every nook and cranny of your home and help you eliminate that post-construction dust and make your new home sparkle!"
					/>
				</div>
				<Locations />
			</div>
		</section>
	);
};

export default Services;

const Card = ({ title, description, image }) => {
	return (
		<div className="flex flex-col items-center lg:items-start">
			<div className="w-16 h-16 flex items-center justify-center">
				<img src={image} alt="" width="100%" height="100%" />
			</div>
			<h3 className="text-2xl font-bold text-black my-4">{title}</h3>
			<p className="text-center lg:text-left">{description}</p>
		</div>
	);
};
