import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./services/ScrollToTop";
import { routes } from "./constants/routes";
import { Suspense, lazy } from "react";
import { Footer, Navbar } from "./components/navigation";
import Loading from "./pages/Loading";
import Home from "./pages/Home";
import ContactPage from "./pages/Contact";
import TermsAndConditions from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AboutUs from "./pages/About";
// lets not import the Home component ( first component to be shown as lazy)
// const Home = lazy(() => import("./pages/Home"));
const Services = lazy(() => import("./pages/Services"));
const FAQs = lazy(() => import("./pages/FAQs"));
const JoinCrew = lazy(() => import("./pages/JoinCrew"));
const Location = lazy(() => import("./pages/Location"));
const Residential = lazy(() => import("./pages/Residential"));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <ScrollToTop>
          <Navbar />
          <Routes>
            <Route path={routes.home} element={<Home />}></Route>
            <Route path={routes.services} element={<Services />}></Route>
            <Route path={routes.faqs} element={<FAQs />}></Route>
            <Route path={routes.joinCrew} element={<JoinCrew />}></Route>
            <Route path={routes.locations} element={<Location />}></Route>
            <Route path={routes.residential} element={<Residential />}></Route>
            <Route path={routes.contact} element={<ContactPage />}></Route>
            <Route path={routes.terms} element={<TermsAndConditions />}></Route>
            <Route path={routes.policy} element={<PrivacyPolicy />}></Route>
            <Route path={routes.about} element={<AboutUs />}></Route>
          </Routes>
          <Footer />
        </ScrollToTop>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
