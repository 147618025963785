import React from "react";

const PrivacyPolicy = () => {
  return (
    <main className="max-w-5xl mx-auto mt-[140px] p-8">
      <h1 className="text-3xl font-bold text-center mb-6">Privacy Policy</h1>

      <p className="mb-6">
        Welcome to House Keeping HQ! Your privacy is of utmost importance to us.
        This Privacy Policy outlines the types of personal information we
        collect, how we use and protect this information, and your rights
        regarding your data. By using our website, you agree to the terms
        described in this policy.
      </p>

      <h2
        className="text-2xl font-bold
       mb-4"
      >
        1. Information We Collect
      </h2>
      <p className="mb-4">
        We collect information to provide better services to all our users. The
        types of information we collect include:
      </p>

      <ul className="list-decimal list-inside mb-6 ml-6">
        <li className="mb-2 list-none">
          <strong className=" list list-none">Personal Information</strong>
          <ul className="list-[lower-alpha] list-inside ml-6 mt-2">
            <li>Contact Details: Name, email address, phone number.</li>
            <li>
              Account Information: Username, password, and other credentials
              required for account setup.
            </li>
            <li>
              Billing Information: Payment details, billing address (collected
              for payment processing purposes).
            </li>
          </ul>
        </li>
        <li className="mb-2  list-none">
          <strong>Usage Data</strong>
          <ul className="list-[lower-alpha] list-inside ml-6 mt-2">
            <li>
              Website Activity: Pages visited, time spent on our website, and
              interactions with our content.
            </li>
            <li>
              Device Information: IP address, browser type, operating system,
              and device identifiers.
            </li>
          </ul>
        </li>
        <li className=" list-none">
          <strong>Communication Data</strong>
          <ul className="list-[lower-alpha] list-inside ml-6 mt-2">
            <li>
              Correspondence: Any messages or communication you send to us,
              including emails and feedback forms.
            </li>
          </ul>
        </li>
      </ul>

      <h2
        className="text-2xl font-bold
       mb-4"
      >
        2. How We Use Your Information
      </h2>
      <p className="mb-4">
        We use the collected information for the following purposes:
      </p>

      <ul className="list-decimal list-inside mb-6 ml-6">
        <li className="mb-2  list-none">
          <strong>Service Delivery</strong>
          <ul className="list-[lower-alpha] list-inside ml-6 mt-2">
            <li>
              Account Management: To manage your account and provide our
              services.
            </li>
            <li>Payment Processing: To handle payments and billing.</li>
            <li>
              Customer Support: To respond to your inquiries and provide
              customer support.
            </li>
          </ul>
        </li>
        <li className="mb-2  list-none">
          <strong>Website Improvement</strong>
          <ul className="list-[lower-alpha] list-inside ml-6 mt-2">
            <li>
              Analytics: To analyze website usage and improve our services and
              user experience.
            </li>
            <li>
              Personalization: To tailor our website content and services to
              your preferences.
            </li>
          </ul>
        </li>
        <li className="mb-2  list-none">
          <strong>Marketing and Communication</strong>
          <ul className="list-[lower-alpha] list-inside ml-6 mt-2">
            <li>
              Promotions: To send you updates, newsletters, and promotional
              materials (with your consent).
            </li>
            <li>
              Feedback: To request feedback and reviews to enhance our
              offerings.
            </li>
          </ul>
        </li>
        <li className="mb-2  list-none">
          <strong>Legal and Security</strong>
          <ul className="list-[lower-alpha] list-inside ml-6 mt-2">
            <li>
              Compliance: To comply with legal obligations and resolve disputes.
            </li>
            <li>
              Security: To protect our website and services from fraudulent
              activities and unauthorized access.
            </li>
          </ul>
        </li>
      </ul>

      <h2
        className="text-2xl font-bold
       mb-4"
      >
        3. Sharing Your Information
      </h2>
      <p className="mb-4">
        We do not sell or rent your personal information to third parties.
        However, we may share your information in the following situations:
      </p>

      <ul className="list-decimal list-inside mb-6 ml-6">
        <li className="mb-2 list-none ">
          <p className="font-bold mb-2 text-2xl">Service Providers</p> We may
          share your information with third-party service providers who assist
          us in delivering our services, such as payment processors, email
          delivery services, and analytics providers.
        </li>
        <li className="mb-2 list-none">
          <p className="font-bold text-2xl mb-2">Legal Obligations</p> We may
          disclose your information if required by law, regulation, or legal
          process, or to protect the rights, property, or safety of House
          Keeping HQ, our users, or others.
        </li>
        <li className="mb-2 list-none">
          <p className="font-bold text-2xl mb-2">Business Transfers</p> In the
          event of a merger, acquisition, or sale of all or a portion of our
          assets, your information may be transferred to the acquiring entity.
        </li>
      </ul>

      <h2
        className="text-2xl font-bold
       mb-4"
      >
        4. Your Rights and Choices
      </h2>
      <p className="mb-4">
        You have certain rights regarding your personal information:
      </p>

      <ul className="list-none list-inside mb-6 ml-6">
        <li className="mb-2">
          <p className="text-2xl font-bold mb-2">Access and Correction</p> You
          can request access to your personal data and ask for corrections if
          you believe it is inaccurate or incomplete.
        </li>
        <li className="mb-2">
          <p className="text-2xl font-bold mb-2">Deletion</p> You can request
          the deletion of your personal data, subject to certain legal
          obligations and exceptions.
        </li>
        <li className="mb-2">
          <p className="text-2xl font-bold mb-2">Opt-Out</p> You can opt out of
          receiving marketing communications from us by following the
          unsubscribe instructions included in our emails or contacting us
          directly.
        </li>
        <li>
          <p className="text-2xl font-bold mb-2">Data Portability</p> You can
          request a copy of your personal data in a structured, commonly used,
          and machine-readable format.
        </li>
      </ul>

      <h2
        className="text-2xl font-bold
       mb-4"
      >
        5. Security of Your Information
      </h2>
      <p className="mb-6">
        We take appropriate technical and organizational measures to protect
        your personal data from unauthorized access, use, or disclosure. These
        measures include encryption, secure servers, and access controls.
        However, no internet transmission or electronic storage is completely
        secure, so we cannot guarantee absolute security.
      </p>

      <h2
        className="text-2xl font-bold
       mb-4"
      >
        6. Cookies and Tracking Technologies
      </h2>
      <p className="mb-6">
        We use cookies and similar tracking technologies to collect and use data
        about your activity on our website. Cookies help us improve our
        services, personalize your experience, and provide analytics. You can
        manage your cookie preferences through your browser settings.
      </p>

      <h2
        className="text-2xl font-bold
       mb-4"
      >
        7. Changes to This Privacy Policy
      </h2>
      <p className="mb-6">
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or legal requirements. We will notify you of any
        significant changes by posting the updated policy on our website and
        updating the “Last Updated” date at the top of this page. We encourage
        you to review this policy periodically to stay informed about how we
        protect your information.
      </p>

      <h2
        className="text-2xl font-bold
       mb-4"
      >
        8. Contact Us
      </h2>
      <p className="mb-4">
        If you have any questions about these Terms and Conditions, You can
        contact us:
      </p>
      <ul className="list-disc list-inside ml-6 mb-6">
        <li>
          By visiting this page on our website:{" "}
          <a
            href="https://housekeepinghq.com/"
            className="text-blue-500 hover:underline"
          >
            https://housekeepinghq.com/
          </a>
        </li>
        <li>By phone number: 253-988-5813</li>
      </ul>

      <p className="text-center mb-10">
        By using our website, you acknowledge that you have read and understood
        this Privacy Policy and agree to our collection, use, and sharing of
        your information as described.
      </p>
    </main>
  );
};

export default PrivacyPolicy;
