import { Accordian } from "../../design/components";

const Cleaners = () => {
  return (
    <section className="w-full max-w-[1500px] mx-auto px-4 py-10">
      <h2 className="text-4xl lg:text-7xl text-black font-bold text-center">
        <span className="text-primary">Time-Saving Cleaners</span>
        <br /> You can Trust
      </h2>
      <p className="text-lg lg:text-2xl font-medium text-black text-center my-4">
        We know that inviting someone to your home is a significant matter. We
        only hire workers after they have completed our rigorous screening and
        selection process – we only want the best-dedicated cleaners that have
        our customer's satisfaction and safety at the top of their minds.
      </p>
      <h4 className="text-4xl lg:text-5xl font-bold text-center mt-8">
        Your Home and Family{" "}
        <span className="text-primary">are safe with us!</span>
      </h4>
      <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-10">
        <div className="space-y-8">
          <Accordian
            title="Bathroom"
            content={
              <>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean tub, shower door and inside of shower</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean toilets in/out, base and behind</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean exterior of vanities</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean and polish counter top, sinks and faucets</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean mirrors</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Dust window sills</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean and disinfect towel bars</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean floors (vacuum, sweep, mop)</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Extra attention to shower & bath</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Remove cobwebs</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Wipe doors, handles & light switches</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean inside cabinets and drawers</span>
                </div>
              </>
            }
          />
          <Accordian
            title="Laundry Room"
            content={
              <>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean tub, shower door and inside of shower</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean toilets in/out, base and behind</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean exterior of vanities</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean and polish counter top, sinks and faucets</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean mirrors</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Dust window sills</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean and disinfect towel bars</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean floors (vacuum, sweep, mop)</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Extra attention to shower & bath</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Remove cobwebs</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Wipe doors, handles & light switches</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean inside cabinets and drawers</span>
                </div>
              </>
            }
          />
        </div>
        <div className="space-y-8">
          <Accordian
            title="Kitchen"
            content={
              <>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean tub, shower door and inside of shower</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean toilets in/out, base and behind</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean exterior of vanities</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean and polish counter top, sinks and faucets</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean mirrors</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Dust window sills</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean and disinfect towel bars</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean floors (vacuum, sweep, mop)</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Extra attention to shower & bath</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Remove cobwebs</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Wipe doors, handles & light switches</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean inside cabinets and drawers</span>
                </div>
              </>
            }
          />
          <Accordian
            title="General Cleaning"
            content={
              <>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean tub, shower door and inside of shower</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean toilets in/out, base and behind</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean exterior of vanities</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean and polish counter top, sinks and faucets</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean mirrors</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Dust window sills</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean and disinfect towel bars</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean floors (vacuum, sweep, mop)</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Extra attention to shower & bath</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Remove cobwebs</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Wipe doors, handles & light switches</span>
                </div>
                <div className="text-[#565656] flex items-center gap-4">
                  <div className="w-1.5 h-1.5 bg-[#565656] rounded-full" />
                  <span>Clean inside cabinets and drawers</span>
                </div>
              </>
            }
          />
        </div>
      </div>
    </section>
  );
};

export default Cleaners;
